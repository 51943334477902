import React, { useState } from 'react';
import { MdWbSunny } from "react-icons/md";
import { FaMoon } from "react-icons/fa";

const ThemeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('color-theme') === 'dark' ||
    (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );

  const toggleTheme = () => {
    const root = document.documentElement;

    if (isDarkMode) {
      root.classList.remove('dark');
      localStorage.setItem('color-theme', 'light');
    } else {
      root.classList.add('dark');
      localStorage.setItem('color-theme', 'dark');
    }

    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className="">
      <button
        onClick={toggleTheme}
        className="flex items-center justify-center p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
      >
        {isDarkMode ? (
          <MdWbSunny  className="h-6 w-6" aria-hidden="true" />
        ) : (
          <FaMoon  className="h-5 w-5" aria-hidden="true" />
        )}
      </button>
    </div>
  );
};

export default ThemeToggle;
