import { Routes, Route } from 'react-router-dom';
import Profile from '../pages/User/Profile'; 
import Announcements from '../pages/User/Announcements'; 
import Attendance from '../pages/User/Attendance';
import ToDoList from '../pages/User/ToDoList'; 
import Settings from '../pages/User/Settings'; 
import Resources from '../pages/User/Resources'; 
import About from '../pages/User/About'; 
import Dashboard from '../pages/User/Dashboard';
import Home from '../pages/User/Home';
import Assets from '../pages/Asset Inventory/Assets';
import Payroll from '../pages/Payroll Management/Payroll';
import AttendanceMonitoring from '../pages/Attendance Monitoring/Attendance-Monitoring';
import Unauthorized from '../pages/User/Unauthorized';

const Main = () => {
  return (
    <div className="pt-16 w-full h-full bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 overflow-x-scroll overflow-y-auto">
      <Routes>
        <Route path="/profile" element={<Profile />} /> 
        <Route path="/announcements" element={<Announcements />} /> 
        <Route path="/attendance" element={<Attendance />} /> 
        <Route path="/todo" element={<ToDoList />} /> 
        <Route path="/settings" element={<Settings />} /> 
        <Route path="/resources" element={<Resources />} />
        <Route path="/about" element={<About />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/payroll-management" element={<Payroll />} />
        <Route path="/assets" element={<Assets />} />
        <Route path="/attendance-monitoring" element={<AttendanceMonitoring />} />
        <Route path="/home" element={<Home />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </div>
  );
};

export default Main;
