import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Datepicker, Select } from 'flowbite-react';
import 'react-datepicker/dist/react-datepicker.css';

const Form = ({ fields, onSubmit, buttonText = 'Add Asset', formId, existingData }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (existingData) {
      setFormData(existingData); 
    }
  }, [existingData]);

  const handleInputChange = (name, value) => {
    // Log the input changes
    console.log(`Input Change - Name: ${name}, Value: ${value}`);
  
    // Check if the field is a date and format it to ISO
    const formattedValue = (name === 'datePurchased' && value) 
      ? new Date(value).toISOString() 
      : value;
  

    if (name === 'assetType') {
      const codePrefix = value === 'TM' ? 'TM' : 'FFOE'; // Adjust prefixes as needed
  
   
      const currentNumber = parseInt(formData.assetCode ? formData.assetCode.slice(2) : '0') || 0; // Get number part after prefix
      const incrementedNumber = currentNumber + 1;
      const generatedCode = `${codePrefix}${incrementedNumber.toString().padStart(3, '0')}`;
      
      setFormData((prev) => ({ ...prev, [name]: value, assetCode: generatedCode }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: formattedValue }));
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault(); 
    console.log('Form Data Submitted:', formData);
    if (onSubmit) {
      onSubmit(formData);
      setFormData({}); //form reset
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-6 gap-4">
        {fields.map((field, index) => (
          <div key={index} className={`col-span-6 sm:col-span-${field.colSpan || 6}`}>
            <label
              htmlFor={field.name}
              className="block text-start mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {field.label}
            </label>

            {field.type === 'textarea' ? (
              <textarea
                id={field.name}
                name={field.name}
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder={field.placeholder}
                value={formData[field.name] || ''}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
              />
            ) : field.type === 'select' ? (
              <Select
                id={field.name}
                name={field.name}
                required={field.required}
                value={formData[field.name] || ''} 
                onChange={(e) => handleInputChange(field.name, e.target.value)}
              >
                <option value="" disabled>
                  {field.placeholder}
                </option>
                {field.options.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            ) :field.type === 'date' ? (
              <DatePicker
              id={field.name}
              selected={formData[field.name] ? new Date(formData[field.name]) : null}
              onChange={(date) => {
                console.log('Date Selected:', date);
                // Convert the selected date to an ISO string without time
                const formattedDate = date ? date.toISOString().split('T')[0] : null;
                handleInputChange(field.name, formattedDate);
              }}
              dateFormat="yyyy-MM-dd" // Format the date as yyyy-MM-dd
              placeholderText={field.placeholder}
              className="block w-full mr-56 bg-gray-50 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            />
            
            ) : (
              <input
                type={field.type || 'text'}
                name={field.name}
                id={field.name}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder={field.placeholder}
                value={formData[field.name] || ''}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
                required={field.required}
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex items-center pt-6 space-x-4 rounded-b dark:border-gray-700">
        <button
          className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-10 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          type="submit"
        >
          {buttonText}
        </button>
      </div>
    </form>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.array,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      colSpan: PropTypes.number,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  formId: PropTypes.string,
  existingData: PropTypes.object,
};

export default Form;
