// src/App.jsx
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Nav from './components/Nav';
import Main from './components/Main';
import Login from './pages/User/Login'; 
import Register from './pages/User/Register'; 
import Dashboard from './pages/User/Login'; 
import Payroll from './pages/Payroll Management/Payroll'; 
import Assets from './pages/Asset Inventory/Assets'; 
import AttendanceMonitoring from './pages/Attendance Monitoring/Attendance-Monitoring'; 
import { AuthProvider } from './context/AuthContext'; 
import ProtectedRoute from './components/ProtectedRoute'; 
import ThemeToggle from './components/ThemeToggle';

const AppRoutes = () => {
    const location = useLocation();
    const isAuthRoute = location.pathname === '/' || location.pathname === '/register' || location.pathname === '/login';

    return (
        <div className="app flex h-screen overflow-hidden">
            {!isAuthRoute && <Sidebar />  } 
            <Routes>
                <Route path="/" element={<Login />} /> 
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route 
                    path="/dashboard" 
                    element={
                        <ProtectedRoute>
                            <Main>
                                <Dashboard />
                            </Main>
                        </ProtectedRoute>
                    } 
                />
                {/* Protect the admin routes inside Main */}
                <Route 
                    path="/payroll-management" 
                    element={
                        <ProtectedRoute allowedRoles={['HR Admin','IT Admin']}>
                            <div className='pl-4 pr-4 pt-20 sm:pl-10 sm:pr-10 w-full h-full bg-white dark:bg-gray-900  text-gray-900 dark:text-gray-100'>
                                <Payroll />
                            </div>
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/assets" 
                    element={
                        <ProtectedRoute allowedRoles={['Assets Admin','IT Admin']}>
                            <div className='pl-4 pr-4 pt-20 sm:pl-10 sm:pr-10 w-full h-full bg-white dark:bg-gray-900  text-gray-900 dark:text-gray-100'>
                                <Assets />
                            </div>
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/attendance-monitoring" 
                    element={
                        <ProtectedRoute allowedRoles={['HR Admin','IT Admin']}>
                            <div className='pl-4 pr-4 pt-20 sm:pl-10 sm:pr-10 w-full h-full bg-white dark:bg-gray-900  text-gray-900 dark:text-gray-100'>
                                <AttendanceMonitoring />
                            </div>
                        </ProtectedRoute>
                    } 
                />
                {/* Protect all other routes under Main */}
                <Route 
                    path="*" 
                    element={
                        <ProtectedRoute>
                            <Main />
                        </ProtectedRoute>
                    } 
                />
            </Routes>
        </div>
    );
};

function App() {
    return (
        <AuthProvider>
            <Router>
                <AppRoutes />
            </Router>
        </AuthProvider>
    );
}

export default App;
