import React from "react";
import { Link } from "react-router-dom";

function Button({ onClick, children, className = "", to }) {
    const buttonContent = (
        <span className={`inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4 ${className}`}>
            {children}
        </span>
    );

    return to ? (
        <Link to={to} className="no-underline">
            {buttonContent}
        </Link>
    ) : (
        <button onClick={onClick} className="no-underline">
            {buttonContent}
        </button>
    );
}

export default Button;
