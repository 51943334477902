// src/components/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
    const { user, role } = useAuth(); // Destructure both user and role from AuthContext

    // Check if the user is authenticated
    if (!user) {
        return <Navigate to="/login" replace />;
    }

    // Check if the user role is allowed
    if (allowedRoles && !allowedRoles.includes(role)) {
        return <Navigate to="/unauthorized" replace />; // Redirect to dashboard or another page if role is not allowed
    }

    return children; // Render the protected components if all checks pass
};

export default ProtectedRoute;
