import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import { FaFacebook, FaMobileAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Avatar } from "flowbite-react";
import { Clipboard } from "flowbite-react"

const Profile = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [assets, setAssets] = useState([]); // State to hold assigned assets
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
    const database = getDatabase();

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            const userRef = ref(database, 'users/' + user.uid);
            const unsubscribe = onValue(userRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setUserDetails(data);
                    if (data.assignedAssets) {
                        const assetPromises = data.assignedAssets.map(asset => {
                            const assetRef = ref(database, `Assets/${asset.id}`); // Corrected template literal
                            return new Promise((resolve) => {
                                onValue(assetRef, (assetSnapshot) => {
                                    resolve(assetSnapshot.val());
                                });
                            });
                        });

                        Promise.all(assetPromises).then(assetsData => {
                            console.log("Fetched Assets Data:", assetsData);
                            setAssets(assetsData.filter(asset => asset !== null)); // Filter out null values
                        });
                    }
                } else {
                    console.log('No user data found.');
                    setUserDetails(null);
                }
                setLoading(false);
            }, (error) => {
                console.error('Error fetching user data:', error);
                setLoading(false);
            });

            return () => unsubscribe();
        } else {
            console.log('No authenticated user found.');
            setLoading(false);
        }
    }, [auth, database]);

    return (
        <div className="">
            {loading ? (
                <p>Loading user details...</p>
            ) : userDetails ? (
                <div className="grid grid-cols-1 px-4 xl:grid-cols-3 xl:gap-4 dark:bg-gray-90">
                    <div className="mb-4 col-span-full xl:mb-0">
                    </div>

                    <div className="col-span-full xl:col-auto">
                        <div className="p-6 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                            <div className="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                                <div>
                                    <div class="flex space-x-4">
                                         <Avatar img="/logo512.png" alt="avatar of Jese"  rounded status="online" statusPosition="bottom-right"/>
                                        <div>
                                            <span class="mb-2 inline-block rounded bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-primary-800 dark:bg-primary-900 dark:text-primary-300">Admin</span>
                                            <h2 class="flex items-center text-xl font-bold leading-none text-gray-900 dark:text-white sm:text-2xl">{userDetails.firstName}  {userDetails.lastName}</h2>
                                        </div>
                                    </div>
                                    <div className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                                        JPG, GIF or PNG. Max size of 800K
                                    </div>
                                    <div className="flex items-center space-x-4">
                                        <button type="button" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                            <svg className="w-4 h-4 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path><path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path></svg>
                                            Upload picture
                                        </button>
                                        <button type="button" className="py-2 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                            <div className="flow-root">
                                <h3 className="text-xl font-semibold dark:text-white">Assigned Assets</h3>
                                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                    {assets.length > 0 ? (
                                        assets.map((asset, index) => (
                                            <li key={index} className="py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="flex-shrink-0">
                                                        <img
                                                            src={asset.imageUrl}
                                                            alt="NOT FOUND"
                                                            className="w-12 h-12 rounded"
                                                        />
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                                            {asset.assetName || "Asset not found"}
                                                        </p>
                                                        <p className="text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                                                            Asset Code: {asset.assetCode || "N/A"}
                                                        </p>
                                                        {/* Display the date assigned */}
                                                        <p className="text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                                                            Date Assigned: {asset.dateAssigned ? new Date(asset.dateAssigned).toLocaleDateString() : "N/A"}
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center">
                                                        <a
                                                            href={`https://www.broadwatermarine.com/assets/${asset.assetCode}`}
                                                            className="px-3 py-2 mb-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                            View
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        <li className="py-4">
                                            <p className="text-sm text-gray-500">No assigned assets found.</p>
                                        </li>
                                    )}

                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-span-2">
                        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                            <h3 className="mb-4 text-xl font-semibold dark:text-white">User information</h3>
                            <form action="#">
                                <div className="grid grid-cols-6 gap-3">
                                    <div className="col-span-6 sm:col-span-3 border border-gray-300  dark:border-gray-600 rounded-lg p-4">
                                        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                                        <p className="text-gray-900 dark:text-white">{userDetails.firstName}</p>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 border border-gray-300  dark:border-gray-600 rounded-lg p-4">
                                        <label htmlFor="last-name" className="block  text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                                        <p className="text-gray-900 dark:text-white">{userDetails.lastName}</p>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 border border-gray-300  dark:border-gray-600 rounded-lg p-4">
                                        <label htmlFor="country" className="block  text-sm font-medium text-gray-900  dark:border-gray-600 dark:text-white">Department</label>
                                        <p className="text-gray-900 dark:text-white">{userDetails.department}</p>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 border border-gray-300  dark:border-gray-600 rounded-lg p-4">
                                        <label htmlFor="city" className="block  text-sm font-medium text-gray-900 dark:text-white">Position</label>
                                        <p className="text-gray-900 dark:text-white">{userDetails.position}</p>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 border border-gray-300  dark:border-gray-600 rounded-lg p-4">
                                        <label htmlFor="address" className="block  text-sm font-medium text-gray-900 dark:text-white">Branch</label>
                                        <p className="text-gray-900 dark:text-white">Subic</p>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 border border-gray-300  dark:border-gray-600 rounded-lg p-4">
                                        <label htmlFor="email" className="block  text-sm font-medium text-gray-900 dark:text-white">Employee ID</label>
                                        <p className="text-gray-900 dark:text-white">{userDetails.employeeID}</p>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 border border-gray-300  dark:border-gray-600 rounded-lg p-4">
                                        <label htmlFor="phone-number" className="block 2 text-sm font-medium text-gray-900 dark:text-white">Date Hired</label>
                                        <p className="text-gray-900 dark:text-white">{userDetails.hireDate}</p>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 border border-gray-300  dark:border-gray-600 rounded-lg p-4">
                                        <label htmlFor="birthday" className="block  text-sm font-medium text-gray-900 dark:text-white">Birthday</label>
                                        <p className="text-gray-900 dark:text-white">{userDetails.birthDate}</p>
                                    </div>
                                </div>
                            </form>
                        </div>

                 
                        
                        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                            <div className="flow-root">
                                <h3 className="text-xl font-semibold dark:text-white">Contact Information</h3>
                                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                    <li className="py-4">
                                        <div className="flex items-center space-x-4">
                                        <span className='hidden lg:block'>Email</span> 
                                            <div className="ml-2 flex-shrink-0 ">
                                               <MdEmail />
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <div class="flex-1 min-w-0">
                                                <div className="relative">
                                                    <label htmlFor="npm-install" className="sr-only">
                                                    Label
                                                    </label>
                                                    <input
                                                    id="npm-install"
                                                    type="text"
                                                    className="col-span-6 block w-full  text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 rounded-lg dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    value={userDetails.email}
                                                    disabled
                                                    readOnly
                                                    />
                                                    <Clipboard.WithIcon valueToCopy={userDetails.email} />
                                                </div>
                                        
                                                </div>
                                            </div> <button href="wwww.broadwatermarine.com" className="px-3 py-2   text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Edit</button>   
                                           
                                        </div>
                                    </li>
                                        <li className="py-4">
                                        <div className="flex items-center space-x-4">
                                           <span className='hidden lg:block'>Phone </span> 
                                            <div className="ml-2 flex-shrink-0 ">
                                               <FaMobileAlt />
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <div class="flex-1 min-w-0">
                                                <div className="relative">
                                                    <label htmlFor="npm-install" className="sr-only">
                                                    Label
                                                    </label>
                                                    <input
                                                    id="npm-install"
                                                    type="text"
                                                    className="col-span-6 block w-full  text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 rounded-lg dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 truncate"
                                                    value={userDetails.email}
                                                    disabled
                                                    readOnly
                                                    />
                                                    <Clipboard.WithIcon valueToCopy={userDetails.email} />
                                                </div>
                                                
                                        
                                                </div>
                                            </div> <button href="wwww.broadwatermarine.com" className="px-3 py-2   text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 truncate">Edit</button>   
                                           
                                        </div>
                                    </li>
                                    <li className="py-4">
                                        <div className="flex items-center space-x-4">
                                            <div className="flex-shrink-0">
                                                <FaFacebook />
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                                    Facebook
                                                </span>
                                            </div>
                                            <div className="inline-flex items-center">
                                                <a href="wwww.broadwatermarine.com" className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Edit</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>

            ) : (
                <p>No user data found.</p>
            )}
        </div>
    );
};

export default Profile;
