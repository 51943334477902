import React, { useState, useEffect } from 'react';
import { RiDeleteBin6Fill } from "react-icons/ri";
import Button from './Button';
import { FaEdit } from "react-icons/fa";
import { Badge } from "flowbite-react";
import { database } from '../firebase'; 
import { ref, onValue } from 'firebase/database';

const Table = ({ headers, data, onEdit, onDelete, totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState({});

  useEffect(() => {
    const usersRef = ref(database, 'users'); 
    onValue(usersRef, (snapshot) => {
      const usersData = {};
      snapshot.forEach((childSnapshot) => {
        const userData = childSnapshot.val();
        usersData[childSnapshot.key] = `${userData.firstName} ${userData.lastName}`; 
      });
      setUsers(usersData);
    });
  }, []);

  const handleDelete = (item) => {
    if (onDelete) {
      onDelete(item);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      onPageChange(newPage);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      onPageChange(newPage);
    }
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const [isModalVisible, setModalVisible] = useState(false);
  const [editableHeaders, setEditableHeaders] = useState(headers);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const handleHeaderClick = (index) => {
    const newHeader = prompt('Edit header:', editableHeaders[index]);
    if (newHeader) {
      const updatedHeaders = [...editableHeaders];
      updatedHeaders[index] = newHeader;
      setEditableHeaders(updatedHeaders);
    }
  };

  return (
    <div>
<div className="flex flex-col">
  <div className="overflow-x-auto">
    <div className="inline-block min-w-full align-middle">
      <div className="overflow-hidden shadow h-[700px] overflow-y-auto ">
        <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
          <thead className="bg-gray-100 dark:bg-gray-700">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input id="checkbox-all" type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                  <label htmlFor="checkbox-all" className="sr-only">Select All</label>
                </div>
              </th>
              <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"></th>
              <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">Asset Name</th>
              <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">Asset Code</th>
              <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">Assignee</th>
              <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">Date Purchased</th>
              <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">Location</th>
              <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">Status</th>
              <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
            {currentData.map((item)  => (
              <tr key={item.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                <td className="w-4 p-4">
                  <div className="flex items-center">
                    <input id={`checkbox-${item.id}`} type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor={`checkbox-${item.id}`} className="sr-only">Select</label>
                  </div>
                </td>
                <td className="flex items-center p-4 space-x-6 whitespace-nowrap">
                  <img className="w-10 h-10 rounded-lg" src={item.imageUrl} alt={`${item.assetName} avatar`} />
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="text-base font-semibold text-gray-900 dark:text-white">{item.assetName}</div>
                </td>
                <td className="p-2 whitespace-nowrap">{item.assetCode}</td>
          
                      {/* Other cells... */}
                      <td className="p-2 whitespace-nowrap">{users[item.assetAssignee] || 'Unknown'}</td>
             
                <td className="p-2 whitespace-nowrap">{item.datePurchased}</td>
                <td className="p-2 whitespace-nowrap">{item.assetLocation}</td>
                <td className="p-2 whitespace-nowrap">
                <div className="flex items-center">
                {item.assetStatus === "In Use" ? (
                  <Badge size="m" color="success">{item.assetStatus}</Badge>
                ) : item.assetStatus === "Disposed" ? (
                  <Badge size="m" color="failure">{item.assetStatus}</Badge>
                ) : item.assetStatus === "Archived" ? (
                  <Badge size="m" color="warning">{item.assetStatus}</Badge>
                ) : item.assetStatus === "Lost" ? (
                  <Badge size="m" color="indigo">{item.assetStatus}</Badge>
                ) : (
                  <Badge size="m">{item.assetStatus}</Badge>
                )}
              </div>

                </td>
                <td className=" space-x-2 whitespace-nowrap">
                        <button className='p-2 text-gray-500 dark:text-gray-400  hover:bg-gray-300 rounded-lg   dark:hover:bg-gray-900' onClick={() => onEdit(item)}><FaEdit /></button>
                       <button className='p-2 text-gray-500 dark:text-gray-400  hover:bg-gray-300 rounded-lg   dark:hover:bg-gray-900' onClick={() => handleDelete(item)}><RiDeleteBin6Fill /></button>
                      </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

      <div className="sticky bottom-0 right-0 items-center w-full p-4 bg-white border-t border-gray-200 sm:flex sm:justify-between dark:bg-gray-800 dark:border-gray-700">
        <div className="flex items-center mb-4 sm:mb-0">
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            Showing <span className="font-semibold text-gray-900 dark:text-white">{(currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, totalItems)}</span> of <span className="font-semibold text-gray-900 dark:text-white">{totalItems}</span>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <a
            href="#"
            onClick={handlePrevious}
            className="inline-flex items-center justify-center flex-1 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Previous
            <svg className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path>
            </svg>
          </a>
          <a
            href="#"
            onClick={handleNext}
            className="inline-flex items-center justify-center flex-1 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Next
            <svg className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Table;
