import React from 'react';

const EditForm = ({ fields, formData, setFormData, buttonText, onSubmit }) => {
  // Generic handler for updating form data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Update the specific field in formData
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Pass the updated formData back to parent
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-6 gap-4">
        {fields.map((field, index) => (
          <div key={index} className={`col-span-${field.colSpan}`}>
            <label>{field.label}</label>
            {field.type === 'select' ? (
              <select
                name={field.name}
                value={formData[field.name] || ''} // Bind formData value to select
                onChange={handleInputChange}
                required={field.required}
                disabled={field.disabled}
                className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
              >
                {field.options.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
          ) : field.type === 'textarea' ? (
            <textarea
              name={field.name}
              placeholder={field.placeholder}
              value={formData[field.name] || ''} // Bind formData value to textarea
              onChange={handleInputChange}
              required={field.required}
              className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
            />
          ) : (
              <input
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                value={formData[field.name] || ''} // Bind formData value to input
                onChange={handleInputChange}
                required={field.required}
                className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex items-center pt-6 space-x-4 rounded-b dark:border-gray-700">
        <button
          className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-10 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          type="submit"
        >
          {buttonText}
        </button>
      </div>
    </form>
  );
};

export default EditForm;
