import React, { useState, useEffect } from 'react';
import logo from '../../assets/development.svg';
import AddAssetModal from '../../CRUD/addAsset';
import { Toast } from "flowbite-react";
import { HiFire } from "react-icons/hi";
import Table from '../../components/table';
import { database } from '../../firebase';
import { ref, onValue, remove } from 'firebase/database';
import DeleteModal from '../../components/DeleteModal';
import EditAsset from '../../CRUD/editAsset';

const Assets = () => {
  const [toastMessage, setToastMessage] = useState(''); // State for toast message
  const [assets, setAssets] = useState([]);
  const [assetToDelete, setAssetToDelete] = useState(null);
  const [isAddAssetModalVisible, setAddAssetModalVisible] = useState(false);
  const [isEditAssetVisible, setEditAssetVisible] = useState(false);
  const [assetToEdit, setAssetToEdit] = useState(null);
  const [toastDuration] = useState(500); 

  const closeAddAssetModal = () => setAddAssetModalVisible(false);
  const closeEditAsset = () => {
    setEditAssetVisible(false);
    setAssetToEdit(null);
  };

  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const headers = ['Photo', 'Asset Name', 'Asset Code', 'Assigned to', 'Date Purchased', 'Asset Location', 'Asset Status'];
  const displayData = assets.map(asset => ({
    assetName: asset.assetName,
    assetCode: asset.assetCode,
    assetAssignee: asset.assetAssignee,
    datePurchased: asset.datePurchased,
    assetLocation: asset.assetLocation,
    assetStatus: asset.assetStatus,
    imageUrl: asset.imageUrl, // Include the image URL here
    id: asset.id,
  }));
  
  
  useEffect(() => {
    const assetsRef = ref(database, 'Assets');
    const unsubscribe = onValue(assetsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const assetsArray = Object.keys(data).map((key) => ({
          id: key,
          assetName: data[key].assetName,
          assetCode: data[key].assetCode,
          assetAssignee: data[key].assetAssignee,
          datePurchased: data[key].datePurchased,
          assetLocation: data[key].assetLocation,
          assetStatus: data[key].assetStatus,
          imageUrl: data[key].imageUrl,
        }));
        setAssets(assetsArray);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleEdit = (item) => {
    setAssetToEdit(item);
    setEditAssetVisible(true);
  };

  const handleDeleteAsset = (itemId) => {
    const assetRef = ref(database, `Assets/${itemId}`);

    remove(assetRef)
      .then(() => {
        console.log(`Successfully deleted asset with ID: ${itemId}`);
        setAssets(prevAssets => prevAssets.filter(asset => asset.id !== itemId));
        setToastMessage('Asset deleted successfully!'); // Set toast message
      })
      .catch((error) => {
        console.error('Error deleting asset from Firebase:', error);
        setToastMessage('Error deleting asset. Please try again.'); // Error message
      });
  };

  const openDeleteModal = (asset) => {
    setAssetToDelete(asset);
    setDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
    setAssetToDelete(null);
  };

  const confirmDelete = () => {
    if (assetToDelete) {
      handleDeleteAsset(assetToDelete.id);
    }
    closeDeleteModal();
  };

  const handleToastClose = () => setToastMessage(''); // Clear the toast message
  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => {
        setToastMessage('');
      }, toastDuration);

      return () => clearTimeout(timer); // Cleanup timer on unmount or toast message change
    }
  }, [toastMessage, toastDuration]);

  return (
    <div className="relative w-full ">
      <div className="p-4 bg-white block rounded-lg sm:flex items-center justify-between lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700">
        <div className="w-full">
          <div className="sm:flex w-full">
            <div className="items-center hidden mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0 dark:divide-gray-700">
              <form className="lg:pr-3">
                <label htmlFor="users-search" className="sr-only">Search</label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <input
                    type="text"
                    name="email"
                    id="users-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search for users"
                  />
                </div>
              </form>
            </div>
            <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
              <button onClick={() => setAddAssetModalVisible(true)} className="bg-blue-700 text-white px-4 py-2 rounded-lg">
                Add Asset
              </button>
            </div>
          </div>
        </div>
      </div>

      <Table
        headers={headers}
        data={displayData}
        onEdit={handleEdit}
        onDelete={openDeleteModal}
        totalItems={assets.length}
        itemsPerPage={10} // Adjust this as necessary
        onPageChange={(newPage) => console.log("Page changed to:", newPage)}
        
      />

      <AddAssetModal
        isVisible={isAddAssetModalVisible}
        onClose={closeAddAssetModal}
        onAssetAdded={() => {
          setToastMessage('Asset added successfully!'); // Set toast message for adding
          closeAddAssetModal();
        }}
      />

      <EditAsset
        isVisible={isEditAssetVisible}
        onClose={closeEditAsset}
        assetId={assetToEdit?.id}
        onAssetUpdated={() => {
          setToastMessage('Asset changes saved successfully!'); // Set toast message for editing
          closeEditAsset();
        }}
      />
      
      <DeleteModal
        isVisible={isDeleteModalVisible}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        itemName={assetToDelete ? assetToDelete.assetName : ''}
      />

      {/* Toast notification */}
      {toastMessage && (
        <Toast className="fixed top-20 right-8">
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-white-200">
            <HiFire className="h-5 w-5" />
          </div>
          <div className="ml-3 dark:text-white text-sm font-normal">
            {toastMessage}
          </div>
          <Toast.Toggle onDismiss={handleToastClose} />
        </Toast>
      )}
    </div>
  );
};

export default Assets;
